let events = require('events').EventEmitter;
window.emitter = new events.EventEmitter();

import Header from  './modules/header.js';

class HomeControllers {
  constructor() {
    window.addEventListener("resize", function() {
      // clearTimeout(window.resizedFinished), window.resizedFinished = setTimeout(function() {
        emitter.emit("window::smartresize")
      // }, 200)
    });
    this.funcRefresh = this.refresh.bind(this);
    emitter.on("window::smartresize", this.funcRefresh);

    if (document.readyState === 'loading') {
      window.addEventListener('DOMContentLoaded', (event) => {
        this.init();
      });
    } else {
      this.init();
    }

    window.addEventListener("load",function() {
      this.finalize()
    }.bind(this));

  }
  init(){

    $(".hero-section-obj1 img").eq(Math.floor(Math.random()*$(".hero-section-obj1 img").length)).addClass("is-active")



    axios.get("./assets/data/works.json").then(res=>{
      this.swiperFn(res.data)
    // res.data.text().then(str=>{
    //   let arr = JSON.parse(str);
    //   console.table(arr);
    // });
    }).catch(err=>{
      console.log(err);
    });

    new Header()
  }
  finalize(){

  }
  refresh(){

  }
  swiperFn(data){

  this.swiper =[]

  Object.keys(data['data']).forEach((key) => {
    if(key < 2 ){
      let numtext;
      let num = parseInt(key)+1
      // console.log(data['data'][key])

      if(num < 99){
        numtext = ( '00' + num ).slice(-2)
      }else{
        numtext = num
      }

      let itemWp_tpl = '<div class="works-section-item">'
          itemWp_tpl += '  <div class="works-section-head">'
          itemWp_tpl += '    <div class="works-section-num"><span class="works-section-num-text">CASE '+numtext+'</span></div>'
          itemWp_tpl += '    <div class="works-section-title">'+ data['data'][key]['case_title'] +'</div>'
          itemWp_tpl += '  </div>'
          itemWp_tpl += '  <div class="swiper-container-wp">'
          itemWp_tpl += '    <div class="swiper-container swiper-container'+key+'">'
          itemWp_tpl += '      <div id="swiper'+key+'" class="swiper">'
          itemWp_tpl += '        <div class="swiper-wrapper">'
          Object.keys(data['data'][key]['images']).forEach((index) => {
            itemWp_tpl += '          <div class="swiper-slide"><img src="'+data['data'][key]['images'][index]['image']+'" alt=""></div>'
          });
          itemWp_tpl += '        </div>'
          itemWp_tpl += '      </div>'
          itemWp_tpl += '      <div class="swiper-works-button-wp">'
          itemWp_tpl += '        <div class="swiper-works-button-prev">'
          itemWp_tpl += '          <img src="./assets/img/button-prev.png" alt="">'
          itemWp_tpl += '        </div>'
          itemWp_tpl += '        <div class="swiper-works-button-next">'
          itemWp_tpl += '          <img src="./assets/img/button-next.png" alt="">'
          itemWp_tpl += '        </div>'
          itemWp_tpl += '      </div>'
          itemWp_tpl += '    </div>'
          itemWp_tpl += '  </div>'
          itemWp_tpl += '  <div class="works-section-text-wp">'
          itemWp_tpl += '    <p class="works-section-text">'+data['data'][key]['text']
          itemWp_tpl += '    </p>'
          itemWp_tpl += '  </div>'
          itemWp_tpl += '</div>'

      $("#js-works-section-inner").append(itemWp_tpl);

      this.params = {
        speed: 500,
        slidesPerView: "auto",
        spaceBetween: 0,
        freeMode: true,
        navigation: {
          nextEl: '.swiper-container'+key+' .swiper-works-button-next',
          prevEl: '.swiper-container'+key+' .swiper-works-button-prev',
        },
        // breakpoints: {
        //   1024: {
        //     loop: true,
        //     loopAdditionalSlides: 1,
        //     loopFillGroupWithBlank: true,
        //     centeredSlides: true,
        //     spaceBetween: 18,
        //     slidesPerView: 'auto',
        //     slidesPerColumn: 1,
        //   }
        // }
      };

      this.swiper[key] = new Swiper("#swiper"+key, this.params);

    }
  });

  AOS.init();
    // const swiper = new Swiper('.swiper', {
    //   // Optional parameters
    //   direction: 'vertical',
    //   loop: true,
    
    //   // If we need pagination
    //   pagination: {
    //     el: '.swiper-pagination',
    //   },
    
    //   // Navigation arrows
    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   },
    
    //   // And if we need scrollbar
    //   scrollbar: {
    //     el: '.swiper-scrollbar',
    //   },
    // });
  }
}
let node = new HomeControllers();